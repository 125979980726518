import React from 'react';
import PropTypes from 'prop-types';

function Input(props) {
  const {
    id, name, type, autoComplete, required, label, onChange, errorMessage,
    hasSuffix, suffixValue, value, disabled, placeholder,
  } = props;
  const hasError = !!errorMessage || false;
  let inputClasses = hasError
    ? 'appearance-none block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md'
    : 'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-400 focus:border-blue-400 sm:text-sm';
  inputClasses = disabled ? 'bg-gray-100 text-gray-600 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm sm:text-sm' : inputClasses;
  const input = (
    <>
      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          id={id}
          name={name}
          type={type}
          value={value}
          autoComplete={autoComplete}
          onChange={onChange}
          aria-invalid={hasError}
          aria-describedby={id}
          className={inputClasses}
          disabled={disabled || ''}
          placeholder={placeholder || ''}
        />
        {hasSuffix && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm" id="price-currency">
              {suffixValue}
            </span>
          </div>
        )}
      </div>
      {hasError && (
        <p className="mt-2 text-sm text-red-600" id={id}>
          {errorMessage}
        </p>
      )}
    </>
  );

  if (label) {
    return (
      <>
        <label
          htmlFor={name}
          className={`block text-sm text-gray-700 ${required && 'font-bold'}`}
        >
          {label}
        </label>
        {input}
      </>
    );
  }
  return input;
}

Input.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  autoComplete: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  errorMessage: PropTypes.string,
  hasSuffix: PropTypes.bool,
  suffixValue: PropTypes.string,
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  placeholder: PropTypes.string,
};

export default Input;
