import React from 'react';
import {
  useQuery, useMutation,
} from '@apollo/client';
import { LightningBoltIcon } from '@heroicons/react/solid';
import FetchingState from './FetchingState';
import { FETCHING_STATE_QUERY } from '../api/queries/fetchingState';
import { FETCH_MUTATION } from '../api/mutations/fetch';
import { LoadingIndicator } from '../layout';
import {
  useApolloDataErrorHandler,
  useApolloErrorErrorHandler,
} from '../helpers/customHooks';

function ControlButtons() {
  const {
    data: fetchingStateData,
    error: fetchingStateError,
  } = useQuery(FETCHING_STATE_QUERY);

  const [
    fetchMutation,
    {
      data: fetchData,
      loading: fetchLoading,
      error: fetchError,
    }] = useMutation(FETCH_MUTATION, {
    onError: () => {},
  });

  const [
    refetchMutation,
    {
      data: refetchData,
      loading: refetchLoading,
      error: refetchError,
    }] = useMutation(FETCH_MUTATION, {
    onError: () => {},
  });

  useApolloErrorErrorHandler(fetchingStateError);

  useApolloDataErrorHandler(fetchData);
  useApolloErrorErrorHandler(fetchError);

  useApolloDataErrorHandler(refetchData);
  useApolloErrorErrorHandler(refetchError);

  return (
    <div className="grid gap-4 pt-4">
      {(fetchLoading
      || refetchLoading)
        && (
          <div className="w-full space-y-4">
            <LoadingIndicator className="flex justify-center" />
          </div>
        )}
      <button
        type="button"
        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-slate-600 hover:bg-slate-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-400"
        onClick={() => fetchMutation()}
      >
        <LightningBoltIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
        Fetch Data from external API (Resets Stored Keywords State)
      </button>
      {fetchingStateData && (<FetchingState item={fetchingStateData.fetchingState} />)}
      {fetchingStateData
              && fetchingStateData.fetchingState
              && fetchingStateData.fetchingState.failed > 0 && (
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-slate-600 hover:bg-slate-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-400"
                onClick={() => refetchMutation({ variables: { refetch: true } })}
              >
                <LightningBoltIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                Refetch Failed Keywords
              </button>
      )}

    </div>
  );
}

export default ControlButtons;
