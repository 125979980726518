import React from 'react';
import { useQuery } from '@apollo/client';
import { DashboardLayout } from '../layout';
import {
  useApolloErrorErrorHandler,
} from '../helpers/customHooks';
import { BLACKLISTS_QUERY } from '../api/queries/blackLists';
import { BlackList, NegativeKeywords, ControlButtons } from '../components';

function Dashboard() {
  const { data, error } = useQuery(BLACKLISTS_QUERY);

  useApolloErrorErrorHandler(error);
  return (
    <DashboardLayout>
      <div className="min-h-full grid gap-4 md:grid-cols-2 pt-4">
        <div>
          {data && !!data.blackLists.length && (
            data.blackLists.map((item) => <BlackList key={item.id} item={item} />)
          )}
          <ControlButtons />
        </div>
        <NegativeKeywords />
      </div>
    </DashboardLayout>
  );
}

export default Dashboard;
