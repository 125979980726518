import React from 'react';
import ErrorPageTempate from './ErrorPageTempate';

function Error500() {
  return (
    <ErrorPageTempate
      label="Internal Server Error."
      code={500}
    />
  );
}

export default Error500;
