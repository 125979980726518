import React from 'react';
import PropTypes from 'prop-types';

function FetchingState(props) {
  const { item } = props;
  return (
    <>
      <div
        className="px-3 relative flex border border-transparent rounded-md py-2 items-center justify-center text-sm font-medium text-black bg-green-200"
      >
        Fetched
        {' '}
        {item.completed}
        {' '}
        /
        {' '}
        {item.total}
      </div>
      {item.failed > 0 && (
        <div
          className="px-3 relative flex border border-transparent rounded-md py-2 items-center justify-center text-sm font-medium text-black bg-rose-200"
        >
          Failed to fetch
          {' '}
          {item.failed}
        </div>
      )}
    </>
  );
}

FetchingState.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
};

export default FetchingState;
