import { gql } from '@apollo/client';

export const LOGIN_MUTATION = gql`
  mutation($email: String!, $password: String!){
      tokenAuth(email: $email, password: $password){ token }
  }
`;

export const USER_CREATE_MUTATION = gql`
  mutation(
    $email: String!,
    ){
      userCreate(input:{
        email: $email,
    })
    {
      response
    }
  }
`;
