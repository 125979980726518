import React from 'react';
import ErrorPageTempate from './ErrorPageTempate';

function Error404() {
  return (
    <ErrorPageTempate
      label="Error: Not Found."
      code={404}
    />
  );
}

export default Error404;
