import { gql } from '@apollo/client';

export const FETCHING_STATE_QUERY = gql`
  query {
    fetchingState{
      completed
      failed
      total
    }
  }
`;
