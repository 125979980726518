import { gql } from '@apollo/client';

export const BLACKLISTS_QUERY = gql`
  query {
    blackLists{
      id
      body
      blacklistType
    }
  }
`;
