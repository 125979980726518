import React from 'react';
import PropTypes from 'prop-types';
import defaultUserImage from '../assets/images/default-user.png';

function getUserImage(profile) {
  if (profile && profile.image) {
    return profile.image;
  }
  return defaultUserImage;
}

function UserImage(props) {
  const { profile, className } = props;
  const image = getUserImage(profile);

  return (
    <img
      className={className}
      src={image}
      alt=""
    />
  );
}

UserImage.propTypes = {
  profile: PropTypes.objectOf(PropTypes.any),
  className: PropTypes.string,
};

export default UserImage;
