/* eslint-disable no-undef */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import ReactDOM from 'react-dom';
import {
  ApolloClient, ApolloProvider, ApolloLink, from, InMemoryCache, HttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import Cookies from 'js-cookie';
import Favicon from 'react-favicon';
import { Provider } from 'react-redux';
import { configureStore } from './redux/Store';
import config from './config';
import * as serviceWorker from './serviceWorker';
import './assets/css/index.css';

import favicon from './assets/images/favicon.ico';

const App = require('./app').default;

const httpLink = new HttpLink({ uri: config.API_URL });

const authLink = setContext((_, { headers }) => {
  const token = Cookies.get('jwt_token');
  if (token) {
    return {
      headers: {
        ...headers,
        authorization: `JWT ${token}`,
      },
    };
  }

  return {
    headers: {
      ...headers,
    },
  };
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = Cookies.get('jwt_token');
  if (!token && !window.location.toString().includes('authentication')) {
    window.location.href = '/authentication/login';
  }

  return forward(operation);
});

const client = new ApolloClient({
  link: from([authMiddleware, authLink, httpLink]),
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <Provider store={configureStore()}>
    <ApolloProvider client={client}>
      <Favicon url={favicon} />
      <App />
    </ApolloProvider>
  </Provider>,
  document.getElementById('root'),
);
serviceWorker.unregister();
