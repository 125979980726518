import React, { Fragment, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { XCircleIcon, CheckCircleIcon } from '@heroicons/react/outline';

import { XIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';
import { ALERT_TYPE } from '../constants';

function AlertIcon(props) {
  const { alertType } = props;
  switch (alertType) {
    case ALERT_TYPE.ERROR:
      return <XCircleIcon className="h-8 w-8 text-red-400" aria-hidden="true" />;
    case ALERT_TYPE.SUCCESS:
      return <CheckCircleIcon className="h-8 w-8 text-green-400" aria-hidden="true" />;
    default:
      return <XCircleIcon className="h-8 w-8 text-red-400" aria-hidden="true" />;
  }
}

export default function Alert(props) {
  const {
    alertType, isOpen, toggle, messageList, isAuthLg,
  } = props;
  const isOneMessage = messageList.length === 1;

  useEffect(() => {
    if (alertType === ALERT_TYPE.SUCCESS) {
      setTimeout(() => {
        toggle();
      }, 5000);
    }
  }, [alertType, toggle]);
  return (
    <>
      {/* Global Alert live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className={`${isAuthLg ? 'absolute' : 'fixed'} z-50 inset-0 flex items-end px-4 py-8 pointer-events-none sm:pt-10 sm:items-start`}
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Alert panel, dynamically insert this into the live
          region when it needs to be displayed */}
          <Transition
            show={isOpen}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className={`${isAuthLg ? 'absolute z-10 ' : ''}max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-2 ring-black ring-opacity-5 overflow-hidden`}>
              <div className="py-6 px-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0 my-auto">
                    <AlertIcon alertType={alertType} />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-1">
                    {isOneMessage ? (
                      <span className="mt-1 text-sm text-gray-700">{messageList[0]}</span>
                    )
                      : (
                        <ul className="list-disc pl-5 space-y-1">
                          {}
                          {!!messageList.length && messageList.map((item, index) => (
                            <li key={index} className="mt-1 text-sm text-gray-700">{item}</li>
                          ))}
                        </ul>
                      )}
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => {
                        toggle();
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}

AlertIcon.propTypes = {
  alertType: PropTypes.string,

};

Alert.propTypes = {
  alertType: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  messageList: PropTypes.arrayOf(PropTypes.string),
  isAuthLg: PropTypes.bool,
};
