import { v4 as uuidv4 } from 'uuid';
import {
  SET_ERROR_ALERTS,
  SET_SUCCESS_ALERTS,
  DISCARD_ALERT,
} from '../constants';
import { ALERT_TYPE } from '../../constants';

const INIT_STATE = {
  alert: null,
};

// eslint-disable-next-line default-param-last
function alertsReducer(state = INIT_STATE, action) {
  const { type, messageList } = action;
  switch (type) {
    case SET_ERROR_ALERTS: {
      const alert = {
        id: uuidv4(),
        messageList,
        alertType: ALERT_TYPE.ERROR,
        show: true,
      };
      return {
        ...state,
        alert,
      };
    }
    case SET_SUCCESS_ALERTS: {
      const alert = {
        id: uuidv4(),
        messageList,
        alertType: ALERT_TYPE.SUCCESS,
        show: true,
      };
      return {
        ...state,
        alert,
      };
    }
    case DISCARD_ALERT: {
      return {
        ...state,
        alert: null,
      };
    }
    default:
      return state;
  }
}

export default alertsReducer;
