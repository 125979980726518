/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Cookies from 'js-cookie';
import { LOGIN_MUTATION } from '../../api/mutations/users';
import { AuthLayout } from '../../layout';
import { Input } from '../../layout/fields';
import {
  useApolloDataErrorHandler,
  useApolloErrorErrorHandler,
} from '../../helpers/customHooks';

function Login() {
  const navigate = useNavigate();
  const [
    login,
    {
      data: loginData,
      loading: loginLoading,
      error: loginError,
    }] = useMutation(LOGIN_MUTATION, { onError: () => null });

  useApolloDataErrorHandler(loginData);
  useApolloErrorErrorHandler(loginError);

  const schema = yup.object({
    email: yup.string().required('Email is Required'),
    password: yup.string().required('Password is Required'),
  });

  const {
    handleSubmit,
    handleChange,
    touched,
    errors: formikErrors,
  } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      const variables = {
        ...values,
      };
      login({ variables });
    },
  });

  useEffect(() => {
    if (loginData) {
      const innerData = loginData[Object.keys(loginData)[0]];
      if (innerData && innerData.token) {
        Cookies.set('jwt_token', innerData.token);
        navigate('/');
      }
    }
  }, [loginData]);

  return (
    <AuthLayout>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <Input
            id="email"
            name="email"
            type="text"
            autoComplete="email"
            errorMessage={touched.email && formikErrors.email}
            label="Email"
            onChange={handleChange}
          />
        </div>

        <div className="space-y-1">
          <Input
            id="password"
            name="password"
            type="password"
            autoComplete="password"
            errorMessage={touched.password && formikErrors.password}
            label="Password"
            onChange={handleChange}
          />
        </div>

        <div>
          <button
            type="submit"
            disabled={loginLoading && 'disabled'}
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 transition duration-150 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-600"
          >
            Log in
          </button>
        </div>
      </form>
    </AuthLayout>
  );
}

export default Login;
