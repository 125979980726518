import {
  SET_ERROR_ALERTS,
  SET_SUCCESS_ALERTS,
  DISCARD_ALERT,
} from '../constants';

export const setErrorAlerts = (messageList) => ({
  type: SET_ERROR_ALERTS,
  messageList,
});

export const setSuccessAlerts = (messageList) => ({
  type: SET_SUCCESS_ALERTS,
  messageList,
});

export const discardAlert = (id) => ({
  type: DISCARD_ALERT,
  id,
});
