import React from 'react';
import {
  useLazyQuery, NetworkStatus,
} from '@apollo/client';
import { FireIcon } from '@heroicons/react/solid';
import { NEGATIVE_KEYWORDS_QUERY } from '../api/queries/negativeKeywords';
import { LoadingIndicator } from '../layout';
import {
  useApolloErrorErrorHandler,
} from '../helpers/customHooks';

function NegativeKeywords() {
  const [
    getKeywords,
    {
      data: keywordsData,
      loading: keywordsLoading,
      error: keywordsError,
      networkStatus: keywordsNetworkStatus,
    }] = useLazyQuery(NEGATIVE_KEYWORDS_QUERY, {
    notifyOnNetworkStatusChange: true,
  });

  useApolloErrorErrorHandler(keywordsError);

  return (
    <div className="grid place-content-center gap-4">
      {(keywordsLoading
      || keywordsNetworkStatus === NetworkStatus.refetch)
        && (
          <div className="w-full space-y-4">
            <LoadingIndicator className="flex justify-center" />
          </div>
        )}
      <button
        type="button"
        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-400"
        onClick={() => getKeywords()}
      >
        <FireIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
        Filter Stored Negative Keywords
      </button>
      <textarea
        rows={25}
        name="negativeKeywords"
        id="negativeKeywords"
        className="max-w-60 shadow-sm focus:ring-orange-400 focus:border-orange-400 block w-full sm:text-sm border-gray-300 rounded-md"
        value={keywordsData && keywordsData.negativeKeywords.join('\n')}
      />
    </div>
  );
}

export default NegativeKeywords;
