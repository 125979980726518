import Cookies from 'js-cookie';

export function removeAuthToken() {
  Cookies.remove('jwt_token');
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}

export function getLabelFromEnumValue(value) {
  const tmp = value.toLowerCase().replaceAll('_', ' ');
  return toTitleCase(tmp);
}
