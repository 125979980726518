/* eslint-disable no-unused-vars */
import React from 'react';
import { useQuery } from '@apollo/client';
import { Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { CURRENT_USER_QUERY } from '../api/queries/users';
import DashboardNavbar from './DashboardNavbar';
import { removeAuthToken } from '../helpers/utils';
import Alert from './Alert';
import { discardAlert } from '../redux/alerts/Action';

// eslint-disable-next-line react/prop-types
export default function DashboardLayout({ children }) {
  const alertsState = useSelector((state) => state.alertsReducer);
  const dispatch = useDispatch();
  // checks that we can do API call to backend, means
  // JWT token is in place and it is valid.
  const { data, error } = useQuery(CURRENT_USER_QUERY);
  if (error) {
    removeAuthToken();
    return <Navigate to="/authentication/login/" />;
  }
  if (data && data.currentUser) {
    const user = data.currentUser;

    return (
      <div className="min-h-screen bg-gray-100">
        <DashboardNavbar
          user={user}
        />
        {alertsState.alert
            && (
              <Alert
                key={alertsState.alert.id}
                alertType={alertsState.alert.alertType}
                isOpen={alertsState.alert.show}
                messageList={alertsState.alert.messageList}
                toggle={() => {
                  dispatch(
                    discardAlert(alertsState.alert.id),
                  );
                }}
              />
            )}
        <main>
          <div className="max-w-7xl mx-auto py-6 px-6 lg:px-8">
            {children}
          </div>
        </main>
      </div>
    );
  }
  return '';
}
