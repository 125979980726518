import { gql } from '@apollo/client';

export const CURRENT_USER_QUERY = gql`
  query {
    currentUser{
      email
      isSuperuser
    }
  }
`;

export const INLINE_USERS_QUERY = gql`
  query {
    users{
      id
      email
    }
  }
`;

export const USER_QUERY = gql`
query($entityId: Int!) {
  user(entityId: $entityId) {
    id
    email
    isActive
    accounts{
      id
      name
    }
  }
}
`;
