import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import Alert from './Alert';
import { discardAlert } from '../redux/alerts/Action';
import logo from '../assets/images/logo.png';

// eslint-disable-next-line react/prop-types
export default function AuthLayout({ children }) {
  const alertsState = useSelector((state) => state.alertsReducer);
  const dispatch = useDispatch();
  // eslint-disable-next-line no-undef
  if (Cookies.get('jwt_token')) {
    return <Navigate to="/" />;
  }

  // We duplicate alert areas here to cover botha lauyouts
  // (for large screens and other)
  return (
    <div className="min-h-screen bg-white flex">
      {alertsState.alert
            && (
            <Alert
              key={alertsState.alert.id}
              alertType={alertsState.alert.alertType}
              isOpen={alertsState.alert.show}
              messageList={alertsState.alert.messageList}
              isAuthLg
              toggle={() => { dispatch(discardAlert(alertsState.alert.id)); }}
            />
            )}
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <img
              className="h-24 w-auto mx-auto"
              src={logo}
              alt="Logo"
            />
            <div className="mt-6">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
