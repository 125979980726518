import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { BLACKLIST_MUTATION } from '../api/mutations/blackLists';
import { NEGATIVE_KEYWORDS_QUERY } from '../api/queries/negativeKeywords';
import { LoadingIndicator } from '../layout';
import { setSuccessAlerts } from '../redux/alerts/Action';
import {
  useApolloDataErrorHandler,
  useApolloErrorErrorHandler,
} from '../helpers/customHooks';
import { getLabelFromEnumValue } from '../helpers/utils';

function BlackList(props) {
  const { item } = props;
  const dispatch = useDispatch();

  const [
    blacklistMutation,
    {
      data,
      loading,
      error,
    }] = useMutation(BLACKLIST_MUTATION, {
    refetchQueries: [{ query: NEGATIVE_KEYWORDS_QUERY }],
    onError: () => {},
  });

  useApolloDataErrorHandler(data);
  useApolloErrorErrorHandler(error);

  const schema = yup.object({
    body: yup.string(),
  });

  const {
    handleSubmit,
    handleChange,
    values: formikValues,
  } = useFormik({
    initialValues: {
      body: item.body,
    },
    validationSchema: schema,
    onSubmit: (values) => {
      const variables = {
        ...values,
        id: item.id,
        blacklistType: item.blacklistType,

      };
      blacklistMutation(
        {
          variables,
        },
      );
    },
  });

  useEffect(() => {
    if (data && data.blackList.response) {
      dispatch(setSuccessAlerts(
        ['Successfully Updated'],
      ));
    }
  }, [data, dispatch]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="p-0.5 -m-0.5 rounded-lg">
        <label htmlFor={item.blacklistType}>
          {`"${getLabelFromEnumValue(item.blacklistType)}" Blacklist`}
        </label>
        <div>
          <textarea
            rows={5}
            name="body"
            id="body"
            className="shadow-sm focus:ring-slate-500 focus:border-slate-500 block w-full sm:text-sm border-gray-300 rounded-md"
            value={formikValues.body}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="mt-2 flex justify-end">
        {loading
          ? (
            <LoadingIndicator />

          )
          : (
            <button
              type="submit"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-slate-600 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
            >
              Update
            </button>
          )}
      </div>
    </form>
  );
}

BlackList.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
};

export default BlackList;
