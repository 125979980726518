import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setErrorAlerts } from '../redux/alerts/Action';

export const useApolloDataErrorHandler = (mutationData) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const innerData = mutationData && mutationData[Object.keys(mutationData)[0]];
    if (innerData && innerData.errors) {
      const errList = [];
      innerData.errors.map((error) => error.messages.map((message) => {
        errList.push(`${error.field}: ${message}`);
        return null;
      }));
      dispatch(setErrorAlerts(errList));
    }
  }, [dispatch, mutationData]);
};

export const useApolloErrorErrorHandler = (mutationData) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (mutationData && mutationData.graphQLErrors) {
      const errList = [];
      mutationData.graphQLErrors.map((error) => {
        errList.push(error.message);
        return null;
      });
      dispatch(setErrorAlerts(errList));
    }
  }, [dispatch, mutationData]);
};

export const usePreviousNonNullish = (value) => {
  const ref = useRef(value);
  useEffect(() => {
    if (value !== null && value !== undefined) {
      ref.current = value;
    }
  });
  return ref.current;
};
