import React from 'react';
import ErrorPageTempate from './ErrorPageTempate';

function Error400() {
  return (
    <ErrorPageTempate
      label="Error: Bad Request."
      code={400}
    />
  );
}

export default Error400;
