import { gql } from '@apollo/client';

export const FETCH_MUTATION = gql`
  mutation(
    $refetch: Boolean,
    ){
      fetch(refetch: $refetch){
          response
        }
  }
`;
