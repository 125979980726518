import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {
  Error400, Error401, Error403, Error404, Error500, Error503,
} from './pages/errorPages';
import { Login } from './pages/auth';
import { Dashboard } from './pages';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Dashboard />} />
        <Route exact path="/authentication/login" element={<Login />} />
        <Route exact path="/400" element={<Error400 />} />
        <Route exact path="/401" element={<Error401 />} />
        <Route exact path="/403" element={<Error403 />} />
        <Route exact path="/404" element={<Error404 />} />
        <Route exact path="/500" element={<Error500 />} />
        <Route exact path="/503" element={<Error503 />} />
        <Route element={<Error404 />} />
      </Routes>
    </Router>
  );
}

export default App;
