/* eslint-disable no-undef */
const config = {};

switch (window.location.hostname) {
  case 'keywords.lux-medien.com':
    config.API_HOST = 'https://api.keywords.lux-medien.com';
    break;
  case 'keywords.test01.lux-medien.com':
    config.API_HOST = 'https://api.keywords.test01.lux-medien.com';
    break;
  default:
    config.API_HOST = 'http://localhost:8000';
    break;
}

config.API_URL = `${config.API_HOST}/graphql/`;
config.ROOT_URL = window.location.origin;

export default config;
