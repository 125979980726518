import React from 'react';
import PropTypes from 'prop-types';

function ErrorPageTempate(props) {
  const { label, code } = props;
  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 shadow sm:rounded-lg">
      <div className="text-center">
        <div className="text-5xl leading-6 font-medium text-gray-600">{code}</div>
        <div className="mt-5 max-w-xl text-xl text-gray-500">
          <p>{label}</p>
        </div>
      </div>
    </div>
  );
}

ErrorPageTempate.propTypes = {
  label: PropTypes.string,
  code: PropTypes.number,
};

export default ErrorPageTempate;
