import { gql } from '@apollo/client';

export const BLACKLIST_MUTATION = gql`
  mutation(
    $body: String!,
    $blacklistType: String!,
    $id: ID!,
    ){
      blackList(input:{
        body: $body,
        blacklistType: $blacklistType,
        id: $id,
      }){
          response{
            id
            body
            blacklistType
          }
          errors{
            field
            messages
          }
        }
  }
`;
